.conciergevet-button-default {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  background: #EFB256;
  border-radius: 61px;
  height: 55px;
}

.conciergevet-button-default:hover {
  background: #f2c177;
  cursor: pointer;
}

.conciergevet-gradient-background {
  background: linear-gradient(86.94deg, #EFB256 20.76%, #f2c177 101.62%), #EFB256;
}

.conciergevet-button-live {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(117, 211, 146, 0.4);
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  border-radius: 34px;
  height: 55px;
}

.conciergevet-button-live:hover {
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  cursor: pointer;
}
