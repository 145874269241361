.hills-button-default {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  background: linear-gradient(88.56deg, #003B75 0.01%, #0061C2 100%);
  box-shadow: 0px 8px 16px rgba(0, 84, 168, 0.16);
  border-radius: 61px;
  height: 55px;
}

.hills-button-default:hover {
  background: linear-gradient(88.56deg, #003B75 0.01%, #0061C2 100%);
  cursor: pointer;
}

.hills-gradient-background {
  background: linear-gradient(88.56deg, #003B75 0.01%, #0061C2 100%);
}

.hills-button-live {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 84, 168, 0.16);
  background: linear-gradient(88.56deg, #CD0A1E 0.01%, #F63C4F 100%);
  border-radius: 34px;
  height: 55px;
}

.hills-button-live:hover {
  background: linear-gradient(88.56deg, #CD0A1E 0.01%, #F63C4F 100%);
  cursor: pointer;
}
