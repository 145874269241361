body {
  /* overflow: hidden; */
}

.button-default {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  background: linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  box-shadow: 0px 8px 16px rgba(82, 180, 233, 0.4);
  border-radius: 61px;
  height: 55px;
}

.button-default:hover {
  background: linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  cursor: pointer;
}

.button-default:focus {
  background: #4BCFFF;
  cursor: pointer;
}

.button-live {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(117, 211, 146, 0.4);
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  border-radius: 34px;
  height: 55px;
}

.button-live:hover {
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  cursor: pointer;
}

.button-training {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(178,102,178, 0.4);
  background: linear-gradient(87.76deg, #954495 20.72%, #9f569f 60.23%), #954495;
  border-radius: 34px;
  height: 55px;
}

.button-training:hover {
  background: linear-gradient(87.76deg, #954495 20.72%, #9f569f 60.23%), #954495;
  cursor: pointer;
}

.button-grey {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  background: linear-gradient(88.56deg, #ececec 0.01%, #f2f2f2 100%), #181818;
  border-radius: 34px;
  height: 55px;
}

.button-grey:hover {
  background: linear-gradient(88.56deg, #ececec 0.01%, #f2f2f2 100%), #181818;
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.intro-slides::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.intro-slides {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.screen-container {
  /* background: linear-gradient(14.92deg, #FFFFFF 62.54%, #E5F5FE 90.32%), #FCFCFC; */
}

.flex-container {
  display: flex;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
}

.input-pointer {

}

.input-pointer:hover {
  cursor: pointer;
}

.universal-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.universal-button:hover {
  cursor: pointer;
}

.file-upload-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.gradient-background {
  background: linear-gradient(86.94deg, #4FA4EF 20.76%, #52C1E9 101.62%), #2AC6FF;
}

.intake-container {
  background: #FFFFFF;
  border: 2px solid #EFEFEF;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.02), 0px 4px 17px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 20px;
  padding-top: 15px;
}

.box-shadow {
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04), 0px 4px 17px rgba(0, 0, 0, 0.02);
}

.screen-gradient {
  background: linear-gradient(14.92deg, #FFFFFF 62.54%, #E5F2FE 90.32%), #FCFCFC;
}
