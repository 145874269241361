.geniustag-button-default {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  background: #F82D3F;
  border-radius: 61px;
  height: 55px;
}

.geniustag-button-default:hover {
  background: #f95665;
  cursor: pointer;
}

.geniustag-gradient-background {
  background: linear-gradient(86.94deg, #f95665 20.76%, #5dc09d 101.62%), #f95665;
}

.geniustag-button-live {
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(117, 211, 146, 0.4);
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  border-radius: 34px;
  height: 55px;
}

.geniustag-button-live:hover {
  background: linear-gradient(87.76deg, #50CD76 20.72%, #75D392 60.23%), linear-gradient(88.56deg, #4FA4EF 0.01%, #4BCFFF 100%), #2A85FF;
  cursor: pointer;
}
